<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Manage framework categories</h3>

	<p>Framework categories can be used to organize the tile and table views of frameworks within <span v-html="app_name"/>. </p>
	<p class="k-no-clear"><strong>Tile View</strong> of Categories (Example from Georgia)</p>
	<img srcset="/docimages/tile_view.png 1.3x" class="k-help-img block">
	
	<p><strong>Table View</strong> of Categories (Example from Georgia)</p>
	<img srcset="/docimages/table_view.png" class="k-help-img block">
	
	<p><strong>Categories</strong> can be helpful to elevate attention to a group of frameworks, indicate frameworks no longer in use, or to highlight frameworks of reference or importance. 
		<ul>
			<li>Common Good Learning Tools manages the categories for <strong>Shared Host</strong> Framework Admin and subscribers (see <span v-html="link('admin', 'Admin Functions)')"></span> to learn more about roles available per plan).</li>
		</ul>
	</p>

	<p>Admin and Editor roles (for Frameworks or the System) can manage categories as follows:
		<ul>
			<li>A new category can be created while creating a new framework or editing the document properties of a pre-existing framework…
				<ol>
					<li>Navigate to the homepage</li>
					<li>In the top-right corner, click <strong>+ NEW FRAMEWORK > Create new framework from scratch</strong></li>
					<li>The Edit Document properties window will appear</li>
					<li>Scroll down towards the bottom to find <strong>Category</strong></li>
					<li>Click <strong>NEW CATEGORY</strong></li>
					<li>Type the new category and click <strong>DONE</strong></li>
					<li>Input the additional framework information and then click <strong>SAVE CHANGES</strong></li>
				</ol>
			</li>
			<li>To edit the document properties of a pre-existing framework, open the framework…
				<ol>
					<li>Click the edit icon</li>
					<li>Click <strong>EDIT FRAMEWORK</strong></li>
					<li>The Edit Document properties window will appear</li>
					<li>Scroll down towards the bottom to find <strong>Category</strong></li>
					<li>Click <strong>NEW CATEGORY</strong></li>
					<li>Type the new category and click <strong>DONE</strong></li>
					<li>Input the additional framework information and then click <Strong>SAVE CHANGES</Strong></li>
				</ol>
			</li>
			<li>To change the Category a framework is located…
				<ol>
					<li>Open the framework</li>
					<li>Click the edit icon</li>
					<li>Click <strong>EDIT FRAMEWORK” > Edit document properties</strong></li>
					<li>Scroll down towards the bottom of the Edit Document window</li>
					<li>Click on the category displayed in the section and select a different category.</li>
				</ol>
			</li>
		</ul>
	</p>

	<p>Only System Admin (or Common Good Learning Tools) can manage categories as follows:
		<ul>
			<li>To change the order of the displayed categories…
				<ol>
					<li>Navigate to the hamburger menu to select <strong>Manage Framework Categories</strong></li>
					<li>A window with the listed categories will appear</li>
					<li>On the navigation cursor next to the category, click, hold, and drag the category to the desired location</li>
				</ol>
			</li>
		</ul>
		<ul>
			<li>To delete a category... 
				<ol>
					<ul>
						<li>Navigate to the hamburger menu to select <strong>Manage Framework Categories</strong></li>
						<li>A window with the listed categories will appear</li>
						<li>On the navigation cursor next to the category, click, hold, and drag the category to the desired location</li>
						<li>To delete a category, click the “<strong>x</strong>” located on the right 
</li>
					</ul>
				</ol>
			</li>
		</ul>
	</p>




</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	